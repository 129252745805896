import React from "react"

import { graphql } from "gatsby"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"

import CaseStudyCTA from "./_components/CaseStudyCTA"
import CaseStudyHeader from "./_components/CaseStudyHeader"
import CaseStudyRow from "./_components/CaseStudyRow"
import CaseStudyStats from "./_components/CaseStudyStats"
import CaseStudyTextContent from "./_components/CaseStudyTextContent"

const CaseStudy = ({ location, data: { strapiRealizations } }) => (
  <TransitionWrapper location={location}>
    <Seo title={strapiRealizations.name} />
    <CaseStudyHeader
      image={strapiRealizations.image.localFile}
      short_description={strapiRealizations.short_description}
      technologies={strapiRealizations.technologies}
      title={strapiRealizations.name}
    />
    <CaseStudyRow
      left={props => (
        <CaseStudyTextContent content={[strapiRealizations.summary, strapiRealizations.about]} {...props} />
      )}
      right={props => <CaseStudyCTA {...strapiRealizations.behance} {...props} />}
    />
    <CaseStudyRow
      inverted
      left={props => <CaseStudyCTA {...strapiRealizations.implementations} {...props} />}
      right={props => <CaseStudyTextContent content={[strapiRealizations.aim]} {...props} />}
    />
    <CaseStudyStats stats={strapiRealizations.stats} />
    <CaseStudyTextContent align="center" content={[strapiRealizations.tech_approach]} />
  </TransitionWrapper>
)

export const query = graphql`
  query RealizationEntityQuery($id: String!) {
    strapiRealizations(id: { eq: $id }) {
      name
      description
      short_description
      technologies {
        technology
      }
      about {
        full
        id
        short
        title
      }
      aim {
        full
        id
        short
        title
      }
      behance {
        button_label
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        subtitle
        title
        url
      }
      implementations {
        button_label
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        subtitle
        title
        url
      }
      opinion {
        author
        opinion
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      stats {
        content
        title
        id
      }
      summary {
        full
        id
        short
        title
      }
      tech_approach {
        full
        id
        short
        title
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default CaseStudy

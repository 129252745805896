import React, { memo } from "react"

import styled from "styled-components"

import Section from "components/common/Section"

const StyledSection = styled(Section)`
  display: grid;
  grid-gap: 5rem;
  align-content: space-between;
  justify-content: ${({ align }) => (align === "center" ? "center" : "end")};

  &:last-child {
    justify-content: start;
  }

  ${({ theme }) => theme.mq.xs} {
    justify-content: center !important;
  }
`

const StyledWrapper = styled.div`
  ${({ theme }) => theme.mq.xs} {
    margin: 0 auto;
  }
`

const StyledTitle = styled.h2`
  font-size: 2.4rem !important;
  text-align: ${({ align }) => align} !important;
  margin-bottom: 2rem !important;
  max-width: 1000px;

  ${({ theme }) => theme.mq.xs} {
    text-align: center !important;
  }
`

const StyledText = styled.p`
  color: ${({ theme }) => theme.color.text.default};
  text-align: ${({ align }) => align};
  font-size: 1.6rem;
  max-width: 1000px;

  ${({ theme }) => theme.mq.xs} {
    text-align: center;
    padding: 0 15px;
  }
`

const Upper = ({ full, align }) => <StyledText align={align}>{full}</StyledText>

const CaseStudyTextContent = ({ content, align }) => {
  if (content.some(element => !element)) return null
  return (
    <StyledSection align={align} color="white" padding="6rem 4rem">
      {content.map(({ title, full, id }) => (
        <StyledWrapper key={id} align={align}>
          <StyledTitle align={align}>{title}</StyledTitle>
          <Upper align={align} full={full} />
        </StyledWrapper>
      ))}
    </StyledSection>
  )
}

CaseStudyTextContent.defaultProps = {
  align: "left",
}

export default memo(CaseStudyTextContent)

import React from "react"

import styled from "styled-components"

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.color.white_000};
`
const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 2000px;
  justify-content: center;
  min-height: 640px;
  margin: 0 auto;

  ${({ theme }) => theme.mq.xs} {
    grid-template-areas: "top" "bottom";
    grid-template-columns: 1fr;
    min-height: unset;

    & > *:first-child {
      grid-area: ${({ inverted }) => (inverted ? "bottom" : "unset")};
    }
  }
`

const CaseStudyRow = ({ left, right, inverted }) => (
  <StyledWrapper>
    <StyledRow inverted={inverted}>
      {left({ left: true })}
      {right({ right: true })}
    </StyledRow>
  </StyledWrapper>
)

export default CaseStudyRow

import React from "react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { hexToRgba } from "styles/theme"

const StyledHeader = styled.header`
  z-index: 1;
  padding: 13.5rem 0 8.5rem 0;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    background: ${({ theme: { color } }) =>
      `linear-gradient(270deg, 
      ${hexToRgba(color.cyan_400, 0.75)} 0%, 
      ${hexToRgba(color.cyan_900, 0.75)} 25%, 
      ${hexToRgba(color.graphite, 0.75)} 100%
      )`};
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 11rem 0 7.5rem 0;
  }
`

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -1;
`

const StyledTitle = styled.h1`
  color: ${({ theme }) => theme.color.text.inverted};
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  max-width: 820px;
  margin: 0 auto;

  font-size: 4.8rem;
  line-height: 4.8rem;

  ${({ theme }) => theme.mq.s} {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
`

const StyledDescription = styled.div`
  max-width: 820px;
  text-align: center;
  font-size: 1.6rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;
  padding-top: 2rem;

  ${({ theme }) => theme.mq.xs} {
    flex-direction: column;
  }
`

const StyledTechnologies = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.text.inverted};
  background-color: rgba(0, 0, 0, 0.25);
  padding: 1.25rem 0;

  ${({ theme }) => theme.mq.s} {
    padding: 1rem 0;
  }

  & div {
    width: 100%;
    max-width: ${({ theme }) => theme.container.xl}px;
    padding: 0 2.5rem;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;

    ${({ theme }) => theme.mq.s} {
      justify-content: center;
      font-weight: 400;

      & span:not(:last-child) {
        margin-right: 1rem;

        &:after {
          content: ",";
        }
      }
    }

    ${({ theme }) => theme.mq.s} {
      flex-wrap: wrap;
    }
  }
`

const StyledTechnologiesLabel = styled.span`
  font-weight: 900;

  ${({ theme }) => theme.mq.s} {
    display: none;
  }
`

const CaseStudyHeader = ({ technologies, image, title, short_description }) => (
  <StyledHeader>
    <StyledBackgroundImage image={getImage(image)} imgStyle={{ objectFit: "fixed" }} style={{ position: "absolute" }} />
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{short_description}</StyledDescription>
    <StyledTechnologies>
      <div>
        <StyledTechnologiesLabel>Tech stack:</StyledTechnologiesLabel>
        {technologies.map(({ technology }) => (
          <span>{technology}</span>
        ))}
      </div>
    </StyledTechnologies>
  </StyledHeader>
)

export default CaseStudyHeader

import React from "react"

import styled from "styled-components"

import Section from "../../../components/common/Section"
import Slider from "../../../components/common/Slider"
import Text from "../../../components/common/Text"
import useWindowWidth from "../../../helpers/useWindowWidth"
import { mq } from "../../../styles/theme"

const StyledSection = styled(Section)`
  ${({ theme }) => theme.mq.xs} {
    padding-bottom: 3.5rem;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const StyledElement = styled.div`
  display: grid;
  grid-gap: 1rem;
  max-width: 320px;
  padding: 0 1.5rem;
  margin: 0 auto;
`

const CaseStudyStats = ({ stats }) => {
  const windowWidth = useWindowWidth()

  return (
    <StyledSection color="graphite">
      {windowWidth && windowWidth <= mq.xs ? (
        <Slider hideArrows>
          {stats.map(({ id, content, title }) => (
            <StyledElement key={id}>
              <Text align="center" fat fontSize="3.2rem" inverted uppercase>
                {title}
              </Text>
              <Text align="center" element="h3" inverted uppercase>
                {content}
              </Text>
            </StyledElement>
          ))}
        </Slider>
      ) : (
        <StyledWrapper>
          {stats.map(({ id, content, title }) => (
            <StyledElement key={id}>
              <Text align="center" fat fontSize="3.2rem" inverted uppercase>
                {title}
              </Text>
              <Text align="center" element="h3" inverted uppercase>
                {content}
              </Text>
            </StyledElement>
          ))}
        </StyledWrapper>
      )}
    </StyledSection>
  )
}

CaseStudyStats.defaultProps = {}

CaseStudyStats.propTypes = {}

export default CaseStudyStats

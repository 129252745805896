import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Button from "../../../components/common/Button"
import Text from "../../../components/common/Text"
import urls from "../../../config/urls"

const StyledWrapper = styled.div`
  position: relative;
`

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;

  ${({ theme }) => theme.mq.xs} {
    position: unset;
    min-height: 50vh;
  }
`

const StyledBottomBar = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
`

const StyledContent = styled.div`
  max-width: 640px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  grid-gap: 2rem;

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: auto;
    justify-items: center;
  }
`

const StyledSubtitle = styled(Text)`
  font-size: 1.4rem;

  ${({ theme }) => theme.mq.s} {
    text-align: center;
    margin-bottom: 1rem;
  }
`

const StyledTitle = styled(Text)`
  font-size: 3.2rem;

  ${({ theme }) => theme.mq.s} {
    font-size: 2.4rem;
    text-align: center;
  }
`
const StyledText = styled.div``

const CaseStudyCTA = ({ button_label, image, title, subtitle, url }) => {
  const redirect = url ? { href: url, target: "_blank" } : { to: urls.portfolio.url }
  return (
    <StyledWrapper>
      <StyledBackgroundImage image={getImage(image && image.localFile)} imgStyle={{ objectFit: "fixed" }} />
      <StyledBottomBar>
        <StyledContent>
          <StyledText>
            <StyledSubtitle bold inverted uppercase>
              {subtitle}
            </StyledSubtitle>
            <StyledTitle fat inverted uppercase>
              {title}
            </StyledTitle>
          </StyledText>
          <Button color="cyan-gradient" fat padding="5px 40px" {...redirect}>
            {button_label}
          </Button>
        </StyledContent>
      </StyledBottomBar>
    </StyledWrapper>
  )
}

CaseStudyCTA.defaultProps = {}

CaseStudyCTA.propTypes = {}

export default CaseStudyCTA
